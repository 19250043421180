@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: rgb(76 29 149);
}

::selection {
  color: white;
  background: rgb(76 29 149);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.select input:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

body {
  font-size: 14px;
  font-weight: 400;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.7);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: inherit;
  font-size: 13px;
  width: 350px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(110, 0, 199, 0.13);
  -webkit-box-shadow: 0 20px 75px rgba(110, 0, 199, 0.13);
  -moz-box-shadow: 0 20px 75px rgba(110, 0, 199, 0.13);
  -o-box-shadow: 0 20px 75px rgba(110, 0, 199, 0.13);
  color: #000;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 1rem;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 14px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #fb8022;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.react-confirm-alert-button-group > button:last-child {
  outline: none;
  background: rgb(76 29 149);
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.react-confirm-alert-button-group > button:last-child:hover {
  background: #7020d6;
}

.react-confirm-alert-button-group > button:hover {
  background: #f79246;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.customCalendar {
  width: 29em !important;
  background: transparent !important;
  box-shadow: none !important;
  padding-bottom: 0 !important;
}

.customCalendar .Calendar__day.-ltr {
  min-height: 2.2em;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #ebedf2 !important;
}

.fc-theme-standard th {
  border: 1px solid #ebedf2 !important;
  border-bottom: none !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-toolbar-title {
  text-transform: capitalize;
  font-size: 20px !important;
  color: rgb(31 41 55) !important;
}

.fc-today-button {
  /* background-color: rgb(76 29 149) !important; */
  background-color: rgb(241 245 249) !important;
  border: none !important;
  outline: none !important;
  font-size: 14px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  color: rgb(31 41 55) !important;
}

.fc-today-button:hover {
  background-color: rgb(226 232 240) !important;
}

.fc-today-button:focus {
  box-shadow: none !important;
}

.fc-prev-button {
  background-color: rgb(241 245 249) !important;
  border: none !important;
  outline: none !important;
  font-size: 14px !important;
  color: rgb(31 41 55) !important;
}

.fc-prev-button:hover {
  background-color: rgb(226 232 240) !important;
}

.fc-prev-button:focus {
  box-shadow: none !important;
}

.fc-next-button {
  background-color: rgb(241 245 249) !important;
  border: none !important;
  outline: none !important;
  font-size: 14px !important;
  color: rgb(31 41 55) !important;
}

.fc-next-button:hover {
  background-color: rgb(226 232 240) !important;
}

.fc-next-button:focus {
  box-shadow: none !important;
}

.fc-col-header {
  color: rgb(31 41 55) !important;
  font-weight: normal !important;
}

.fc-col-header a {
  font-weight: normal !important;
  font-size: 13px !important;
  text-transform: capitalize;
}

.fc-day-today {
  background-color: transparent !important;
}

.fc-daygrid-day {
  cursor: pointer;
}

.fc-daygrid-day-number {
  padding: 6px !important;
  margin-top: 5px;
  margin-right: 5px;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 23px;
  border-radius: 35px;
  font-size: 12px !important;
}

.fc-day-today .fc-daygrid-day-number {
  background-color: rgb(76 29 149) !important;
  color: white !important;
}

.fc-h-event {
  background-color: transparent !important;
  border: none !important;
}

.__react_component_tooltip.show {
  background-color: #270740;
  color: #fff;
  visibility: hidden;
}

.__react_component_tooltip.show::after {
  border-right-color: #270740 !important;
}

.menu-item .__react_component_tooltip.show {
  visibility: visible !important;
}

.__react_component_tooltip.show {
  visibility: visible;
}

.fc-media-screen {
  height: 1000px;
}
/*
@media only screen and (max-width: 1024px) {
  .fc-media-screen {
    height: 490px;
  }

  .fc-daygrid {
    height: 446px !important;
  }
} */

canvas {
  position: absolute !important;
}

.mytable tbody tr.relative:hover::after {
  content: "";
  position: absolute;
  top: calc(50% - (calc(100% - 10px) / 2));
  left: 0;
  width: 4px;
  height: calc(100% - 10px);
  background-color: rgb(249 115 22);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
